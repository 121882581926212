import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter, NavLink, Routes, Route } from 'react-router-dom';
import Tabler from "./icons/Tabler";
import './App.css';

import AllProjectsRaw from "./content/projectData";
import Footer from './components/Footer';


const AllProjects = AllProjectsRaw.reverse();
const AccentColors = ['#70c4a4', '#fe797b', '#ffc660'];

const pages = [
  {
    id: 0, path: '/', title: 'home',
    component: lazy(() => import('./routes/Home'))
  },
  {
    id: 1, path: '/projects', title: 'projects',
    component: lazy(() => import('./routes/Projects'))
  },
  {
    id: 2, path: '/about', title: 'about',
    component: lazy(() => import('./routes/About'))
  },
];


const App = () => {
  const [isYoked, setYoked] = useState(false);

  const Yoke = () => {
    setYoked((prevYoked) => {
      window.document.body.style.overflow = prevYoked ? "" : "hidden" ;
      return !prevYoked;
    });
  };

  return (
    <BrowserRouter>
      <header className={"top-bar" + (isYoked ? " yoked" : "")}>
        <div className="brand">portfolio</div>
        <nav>{pages.map((page, idx) => 
          <NavLink to={page.path} style={{ borderColor: AccentColors[idx] }} key={page.id}>{page.title}</NavLink>
          )}</nav>
        <button
          type="button"
          onClick={ Yoke }>
          <Tabler.Menu className="burger" />
        </button>
      </header>
      <section>
        <div className="content">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {
                pages.map((page) =>
                  <Route path={page.path} element={<page.component projects={AllProjects} accentColors={AccentColors} />} key={page.id} />
                )
              }
            </Routes>
          </Suspense>
        </div>
        <div className="nav-shim">
          <aside>
            <nav>{pages.map((page, idx) => 
              <NavLink
                to={page.path} key={page.id}
                style={{ borderColor: AccentColors[idx] }}
                onClick={() => setYoked(false)}>
                {page.title}
              </NavLink>
              )}</nav>
          </aside>
        </div>
      </section>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
export default [
  {
    id: 0, title: 'dice', tags: ['tumblr'],
    imageImports: import('./project1.jpg'),
    description: 'a responsive, single column sidebar theme. features left or right sidebar, custom google fonts, icon shape, post sizes & colours. ',
    link: ''
  },
  {
    id: 1, title: 'timeless', tags: ['tumblr', 'header'],
    imageImports: import('./project2.jpg'),
    description: 'a responsive single column, header theme. features custom google fonts, icon shape, post sizes & colours.',
    link: ''
  },
  {
    id: 2, title: 'taegri', tags: ['figma'],
    imageImports: import('./project3.jpg'),
    description: 'custom android music player app.',
    link: ''
  },
  {
    id: 3, title: 'instagram page', tags: ['tumblr'],
    imageImports: import('./project4.jpg'),
    description: 'a look-a-like instagram biography page.',
    link: ''
  },
  {
    id: 4, title: 'browser page', tags: ['tumblr'],
    imageImports: import('./project5.jpg'),
    description: 'a look-a-like browser biography page.',
    link: ''
  },
  {
    id: 5, title: 'floating cards', tags: ['tumblr'],
    imageImports: import('./project6.jpg'),
    description: 'a look-a-like browser biography page with responsive, floating cards.',
    link: ''
  },
  {
    id: 6, title: 'atlantis', tags: ['tumblr', 'sidebar'],
    imageImports: import('./project7.jpg'),
    description: 'a responsive, single-column sidebar theme. features drop-down links & optional sidebar image. custom colours & sizes on everything.',
    link: ''
  }
];
import React, { useState, useEffect } from "react";

function LazyImage({ imgImports, loading, fallback }) {
  const [image, setImage] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (imgImports) {
      imgImports.then(
        (value) => {
          setImage(value.default);
          setLoading(false);
        },
        (err) => {
          setImage(null);
          setLoading(false);
          throw err;
        });
    }
  }, [imgImports]);

  if (isLoading) {
    return ( loading || 'Loading...' );
  } else {
    return image ? <img src={image} loading="lazy" /> : (fallback || 'broken...');
  }
}

export default React.memo(LazyImage, (prev, next) => prev.imgImports === next.imgImports);
import React from "react";
import LazyImage from "./LazyImage";
import "./KofiLink.css";

const KofiLink = React.memo(() => (
  <a className="kofi-link" href='https://ko-fi.com/A8673H0V' target='_blank'>
    <LazyImage imgImports={import('../icons/kofi-logo.jpg')} />
  </a>
));

export default KofiLink;
import React from "react";
import "./Footer.css";
import Tabler from "../icons/Tabler";
import KofiLink from "./KofiLink";

const Footer = React.memo((props) =>
  <footer>
    <div className="socials">
      <KofiLink />
      <a href=""><Tabler.Github width={24} height={24} /></a>
      <a href=""><Tabler.Figma width={24} height={24} /></a>
      <a href=""><Tabler.Dribbble width={24} height={24} /></a>
      <a href=""><Tabler.Instagram width={24} height={24} /></a>
    </div>
    <div className="fin">
      powered by react
    </div>
  </footer>
);

export default Footer;